<template>
  <ul class="basic-information clear">
    <!-- v-if="!ite.isHide" -->
    <li v-for="(ite,i) in item.dataList" :key="i" class="flex-box" :class="ite.className">
      <span class="li-label" :style="'width:'+item.labelWidth+'px'">{{ ite.label }}</span>
      <infoTemplate :item="ite" :class-name="'flex1'" />
    </li>
  </ul>
</template>

<script>
import infoTemplate from './infoTemplate.vue'
export default {
  components: {
    infoTemplate
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
